<template>
  <div>
    <section id="area-screen" class="home-section-1 scrolled">
      <div id="user-area" class="home-section-1-inner-wrapper">
        <div class="home-section-1-bg">
          <div id="user-area-inner-form">
            <!-- <div id="user-area-close"><i class="fas fa-times"></i></div> -->
            <div id="form-inner">
              <div class="diagonal-form">Área de Cliente</div>
              <div class="title-with-strap white h1">Bem-Vindo!</div>
              <span
                class="font-size-24"
                style="
                  padding-left: 10px;
                  font-weight: normal;
                  color: #fff;
                  margin-bottom: 15px;
                "
              >
                Complete o seu perfil para continuar
              </span>
              <div id="form-fields">
                <div class="row">
                  <div class="field">
                    <i class="fa fa-user"></i>Nome de Utilizador
                  </div>
                  <input
                    id="novo-nome"
                    type="text"
                    placeholder="Nome de Utilizador"
                    class="novo-user-form"
                  />
                </div>
                <div class="row">
                  <div class="field">
                    <i class="fa fa-envelope" aria-hidden="true"></i>E-mail
                  </div>
                  <input
                    id="novo-email"
                    type="text"
                    placeholder="E-mail"
                    class="novo-user-form"
                  />
                </div>
                <div class="row">
                  <div class="field">
                    <i class="fa fa-lock"></i>Nova palavra-passe
                  </div>
                  <input
                    id="novo-password"
                    type="password"
                    placeholder="Nova palavra-passe"
                    class="novo-user-form"
                  />
                </div>
                <div class="row">
                  <div class="field">
                    <i class="fa fa-lock"></i>Repetir palavra-passe
                  </div>
                  <input
                    id="novo-password-2"
                    type="password"
                    placeholder="Repetir palavra-passe"
                    class="novo-user-form"
                  />
                </div>
                <a
                  id="botao-actualizar"
                  class="button ghost diagonal white"
                  @click="updateUser()"
                  >Confirmar</a
                >
                <div
                  id="resultado-update"
                  class="row"
                  style="text-align: center"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-section">
      <header>
        <div class="header-inner-wrapper">
          <div class="elemento-right">
            <img src="/assets/imagens/glintt_logo_footer.svg" alt="logotipo" />
          </div>
        </div>
      </header>
      <main>
        <div class="main-inner-wrapper">
          <div class="font-grande-home elemento-left light-gray">Bem-vindo</div>
          <br />
          <a
            @click="$router.go(-1)"
            data-v-7017a106=""
            id="voltar"
            class="elemento-left light-grey"
            >Voltar</a
          >
          <div class="elemento-center">
            <form class="form-login">
              <div class="form-linha">
                <div
                  class="input-item item-width-100 input-com-icone user-icone"
                >
                  <input
                    id="username"
                    type="text"
                    value=""
                    placeholder="Nome de utilizador"
                    name="NomeUtilizador"
                    class="more-padding-top-bottom"
                  />
                </div>
              </div>
              <div class="form-linha">
                <div
                  class="input-item item-width-100 input-com-icone password-icone"
                >
                  <input
                    id="password"
                    type="password"
                    value=""
                    placeholder="Palavra-passe"
                    name="Palavra-passe"
                    class="more-padding-top-bottom"
                  />
                </div>
              </div>
              <div class="links-extra">
                <div class="link-item">
                  <input
                    type="checkbox"
                    v-model="checked"
                    name="Lembrar"
                    id="checkbox"
                    class="custom-checkbox"
                  /><label for="checkbox">Lembrar-se de mim</label>
                </div>
                <!-- <span class="separador"> / </span> -->
                <div class="link-item">
                  <router-link to="/reset-password" class="simple-link"
                    >Esqueceu-se da sua palavra-passe?</router-link
                  >
                </div>
              </div>

              <div id="logiResult"></div>

              <div class="button-wrapper">
                <button
                  id="button-submit"
                  class="button diagonal font-weight-500 text-uppercase"
                  @click="loginForm()"
                >
                  Iniciar sessão
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  import CryptoJS from "crypto-js/crypto-js";

  export default {
    name: "login",
    data() {
      return {
        user_id: "",
        name: "",
        email: "",
        username: "",
        user_level: "",
        checked: false,
      };
    },
    methods: {
      loginForm: function () {
        event.preventDefault();

        var username = document.querySelector("#username").value;
        var password = document.querySelector("#password").value;

        var dados = {
          username,
          password,
        };
        this.submitLogin(dados, "form");
      },
      submitLogin: async function (dados, origin) {
        var campoResposta = document.querySelector("#logiResult");
        campoResposta.innerHTML = "A validar...";

        var result = await this.doLogin(dados);

        if (result.resultado == "OK") {
          if (result.user_level == "0") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Farmacias",
            });
            // Analytics pixel
            if ("ga" in window) {
              let tracker = ga.getAll()[0];
              if (tracker) {
                tracker.send("event", "Farmacias", "Farmacias", "Objetivo");
              }
            }
            // end of analytics pixel
          }

          this.user_id = result.userID;
          this.username = result.username;
          this.user_level = result.user_level;
          this.email = result.email;
          this.name = result.name;
          this.anf = result.anf;

          campoResposta.innerHTML = "Bem-vindo!";

          if (result.first_access == 1) {
            this.updateUserShow();
          } else {
            window.sessionStorage.setItem("user_id", this.user_id);
            window.sessionStorage.setItem("username", this.username);
            window.sessionStorage.setItem("user_level", this.user_level);
            window.sessionStorage.setItem("email", this.email);
            window.sessionStorage.setItem("name", this.name);
            window.sessionStorage.setItem("anf", this.anf);

            if (this.checked && origin == "form") {
              var hash = CryptoJS.AES.encrypt(
                `${dados.password}`,
                "%SW91@0[wXLb8Zt"
              ).toString();

              this.$cookies.set("username", `${this.username}`, "30d");
              this.$cookies.set("token", `${hash}`, "30d");
            }

            let redirectPath = window.sessionStorage.getItem("redirectPath");
            let router = this.$router.go(-1);

            setTimeout(function () {
              router.push(redirectPath || "/home");
              // window.location.href = redirectPath || "/home"
            }, 500);

            window.sessionStorage.removeItem("redirectPath");
          }
        } else {
          if (origin == "cookies") {
            this.$cookies.remove("username");
            this.$cookies.remove("token");
            campoResposta.innerHTML = "";
          } else {
            campoResposta.innerHTML = "Utilizador ou password errados";
          }
        }

        // displayResult.innerHTML = result.resultado
      },
      updateUser: async function () {
        var campos = document.querySelectorAll(".novo-user-form");

        var email = document.querySelector("#novo-email").value;
        var nome = document.querySelector("#novo-nome").value;
        var pass = document.querySelector("#novo-password").value;
        var pass2 = document.querySelector("#novo-password-2").value;

        var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (nome == "" || nome == null) {
          document.querySelector("#resultado-update").innerHTML =
            "O nome é um campo obrigatório";

          return false;
        } else if (email == "" || email == null) {
          document.querySelector("#resultado-update").innerHTML =
            "O email é um campo obrigatório";

          return false;
        } else if (!emailReg.test(email)) {
          document.querySelector("#resultado-update").innerHTML =
            "O email introduzido é inválido";

          return false;
        } else if (pass == "" || pass == null || pass2 == "" || pass2 == null) {
          document.querySelector("#resultado-update").innerHTML =
            "No primeiro acesso tem sempre de indicar uma nova password";

          return false;
        } else if (pass != pass2) {
          document.querySelector("#resultado-update").innerHTML =
            "As duas passwords não correspondem";

          return false;
        } else {
          document.querySelector("#resultado-update").innerHTML =
            "A actualizar perfil...";

          let apibody = {
            user_id: this.user_id,
            name,
            email,
            pass,
          };

          const res = await this.updateUserAPI(apibody);

          if (res.resultado == "KO") {
            document.querySelector("#resultado-update").innerHTML =
              "Alguma coisa correu mal...<br>Por favor tente mais tarde";
          } else {
            this.email = email;
            this.name = nome;

            window.sessionStorage.setItem("user_id", this.user_id);
            window.sessionStorage.setItem("username", this.username);
            window.sessionStorage.setItem("user_level", this.user_level);
            window.sessionStorage.setItem("email", this.email);
            window.sessionStorage.setItem("name", this.name);

            setTimeout(function () {
              window.location.href = "/home";
            }, 200);

            document.querySelector("#resultado-update").innerHTML =
              "Tudo pronto!";
          }
        }
      },
      updateUserShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = this.name;
        document.querySelector("#novo-email").value = this.email;

        document.querySelector("#area-screen").className = "home-section-1";

        document.querySelector(".form-login").innerHTML =
          "<i class='fa fa-check' style='font-size:80px; color: var(--cor-principal)'></i><br>Login realizado com sucesso!";
      },
      doLogin: async function (apiBody) {
        try {
          var response = await fetch(
            "https://bydservices.pt/glintt-api/public/api/login",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(apiBody, null, 2),
            }
          );
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      updateUserAPI: async function (apiBody) {
        try {
          var response = await fetch(
            "https://bydservices.pt/glintt-api/public/api/user/update",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(apiBody, null, 2),
            }
          );
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    mounted() {
      let isLogged = window.sessionStorage.getItem("user_id");
      let hasCookies =
        this.$cookies.isKey("username") && this.$cookies.isKey("token");

      console.log(isLogged);

      if (isLogged != null) {
        this.$router.push("/home");
        // window.location.href = "/home"
      } else if (hasCookies) {
        const username = this.$cookies.get("username");
        const token = this.$cookies.get("token");
        let password = CryptoJS.AES.decrypt(token, "%SW91@0[wXLb8Zt").toString(
          CryptoJS.enc.Utf8
        );

        let dados = {
          username,
          password,
        };
        this.submitLogin(dados, "cookies");
      }

      $(document).ready(function () {
        $("#user-area-close").click(function () {
          $(".home-section-1").addClass("scrolled");
        });

        var $imagensWrapper = $(".seccao-promo-code .imagens-wrapper");

        var galleryThumbs = new Swiper(".gallery-thumbs", {
          spaceBetween: 10,
          slidesPerView: 5,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });

        //Slider
        var SliderPromoCode = new Swiper(
          ".main-coluna-imagens .swiper-container",
          {
            slidesPerView: 1,
            slidesPerGroup: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },

            thumbs: {
              swiper: galleryThumbs,
            },
          }
        );
      });
    },
    updated() {
      let isLogged = window.sessionStorage.getItem("user_id");

      if (isLogged != null) {
        window.location.href = "/home";
      }
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
    left: -19% !important;
  }
  .button-wrapper {
    margin-top: 4% !important;
  }
  .form-login .button {
    margin-top: 0% !important;
  }
  #logiResult {
    line-height: 20px;
    min-height: 20px;
    text-align: center;
    color: #444;
    font-size: 14px;
    margin-top: 4%;
  }
  .bebas {
    font-family: "BebasNeue" !important;
  }
  #entrar:hover {
    font-weight: bold;
    cursor: pointer;
  }

  .home-section main {
    height: calc(100vh - 70px);
    /* padding-top: 100px; */
    display: flex;
    align-items: center;
  }
  .home-section main .main-inner-wrapper {
    margin-top: unset;
    width: 100%;
  }

  input {
    width: 110%;
  }

  input[type="checkbox"].custom-checkbox ~ label:after {
    content: "X";
    top: -4px;
  }
  .links-extra .link-item:hover {
    cursor: pointer;
  }
  .form-login {
    z-index: 9999;
  }

  .scrolled {
    display: none;
  }

  #voltar {
    font-size: 32px;
    color: rgb(46, 183, 142);
    cursor: pointer;
  }

  #button-submit:hover {
    cursor: pointer;
  }

  .button.diagonal::before {
    background-color: rgb(46, 183, 142) !important;
  }

  /* #botao-actualizar {
      margin-left: 70%;
      width: fit-content;
      margin-top: 30px;
    }
    #botao-actualizar:hover {
      cursor: pointer;
      font-weight: bold;
    } */

  @media only screen and (max-width: 640px) {
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
      display: none;
    }
    #seccao-servicos #user-area .home-section-1-bg {
      height: 100vh;
      width: 100vw;
      top: 0;
    }
    #form-inner {
      position: absolute;
      width: calc(100vw - 30px);
    }
    #log-out {
      position: fixed;
      bottom: 15px;
      right: 15px;
      color: #fff;
    }
    #user-area {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999;
    }
    #user-area-inner-form {
      width: 100vw;
      height: 100vh;
    }
    #form-fields {
      width: calc(100% - 20px);
    }

    .row {
      flex-direction: column;
      width: 90%;
    }
    .field {
      min-width: 100% !important;
      margin-left: 0px !important;
    }
    .row input {
      width: 100% !important;
    }
    #botao-actualizar {
      margin-left: 0;
      align-self: flex-end;
    }
  }
</style>
